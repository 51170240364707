import { useEffect } from "react";
import useTokens from "../hooks/tokens";
import useSelectedTokens from '../contexts/SelectedTokensContext';
import useTokenSort from "../hooks/sort";
import useStandard from "../hooks/standard";
import useSelectedAll from "../hooks/selectedAll";

const _TokenList = () => {
    const { tokens, fetchTokens, setTokens } = useTokens();
    const { sortTokens, sortDirection, setSortDirection } = useTokenSort();
    const { selectedTokens, setSelectedTokens } = useSelectedTokens();
    const { standard, setStandard } = useStandard();
    const { selectedAll, setSelectedAll } = useSelectedAll();

    useEffect(() => {
        fetchTokens();
    }, []);

    const handleRowClick = (tokenAta: string) => {
        const newSelection = selectedTokens.includes(tokenAta)
            ? selectedTokens.filter((ata) => ata !== tokenAta)
            : [...selectedTokens, tokenAta];
        setSelectedTokens(newSelection);
    };

    const handleAllClick = () => {
        // select or deselect all tokens
        const newStandardSelectedAll = !selectedAll[standard];
        let newSelectedTokens: string[] = [];
        if (newStandardSelectedAll) {
            newSelectedTokens = Array.from(new Set([...selectedTokens, ...tokens[standard].map((token) => token.ata)]) );
        } else {
            const ataSet = new Set(tokens[standard].map((token) => token.ata));
            newSelectedTokens = selectedTokens.filter((ata) => !ataSet.has(ata));
        }
        setSelectedAll({ ...selectedAll, [standard]: newStandardSelectedAll });
        setSelectedTokens(newSelectedTokens);
    };

    const handleSortClick = () => {
        const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(newDirection);
        const sortedFT = sortTokens(tokens.FT, newDirection);
        const sortedNFT = sortTokens(tokens.NFT, newDirection);
        setTokens({ 'FT': sortedFT, 'NFT': sortedNFT });
    };

    return (
        <div style={{
            width: '100%',
            margin: '0 auto',  // Add this to center the container
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>

            <table style={{
                width: '55%',
                minWidth: '650px',
                borderCollapse: 'collapse',
                tableLayout: 'fixed'
            }}>
                <thead>
                    <tr>
                        <th colSpan={5} className="cell" style={{position: 'relative'}}>
                            <div style={{textAlign: 'center', fontWeight: 'normal'}}>
                                <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setStandard('FT')}>
                                    {standard === 'FT' ? <b>Fungible Tokens</b> : 'Fungible Tokens'}
                                </span>
                                &nbsp;/&nbsp;
                                <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setStandard('NFT')}>
                                    {standard === 'NFT' ? <b>Non-Fungible Tokens</b> : 'Non-Fungible Tokens'}
                                </span>
                            </div>
                            <u style={{position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: 'normal'}} onClick={fetchTokens}>
                                Refresh
                            </u>
                        </th>
                    </tr>
                    <tr>
                        <th
                            className="cell"
                            style={{cursor: 'pointer'}}
                            onClick={() => handleAllClick()}
                        >
                            <u>Symbol</u>
                        </th>
                        <th className="cell">Token Account</th>
                        <th className="cell">Associated Token Account (ATA)</th>
                        <th
                            className="cell"
                            style={{cursor: 'pointer'}}
                            onClick={() => handleSortClick()}
                        >
                            <u>Token Balance</u>&nbsp;{sortDirection === 'asc' ? '↑' : '↓'}
                        </th>
                        <th className="cell">Rent (SOL) Reserved in ATA</th>
                    </tr>
                </thead>
            </table>

            <div style={{
                width: '55%',
                minWidth: '650px',
                maxHeight: '65vh',
                overflowY: 'auto',
            }}>
                <table style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    tableLayout: 'fixed',
                }}>
                    <tbody>
                        {tokens[standard].map((token, index) => (
                            <tr
                                key={token.ata}
                                style={{
                                    backgroundColor: selectedTokens.includes(token.ata) ? 'white' : 'transparent',
                                    borderTop: index === 0 ? '1px solid #F6F5F2' : undefined,
                                    borderBottom: index === tokens[standard].length - 1 ? '1px solid #F6F5F2' : undefined,
                                    height: 'calc(65vh / 15)',
                                }}
                            >
                                <td
                                    className="cell"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => handleRowClick(token.ata)}
                                >
                                    <u>{token.symbol}</u>
                                </td>
                                <td
                                    className="cell"
                                    style={{cursor: 'pointer'}}
                                    title={token.mint}
                                    onClick={() => {
                                        window.open(`https://solscan.io/account/${token.mint}`, "_blank");
                                    }}
                                >
                                    <u>{token.mint.substring(0, 6)}...{token.mint.substring(token.mint.length - 6)}</u>
                                </td>
                                <td
                                    className="cell"
                                    style={{cursor: 'pointer'}}
                                    title={token.ata}
                                    onClick={() => {
                                        window.open(`https://solscan.io/account/${token.ata}`, "_blank");
                                    }}
                                >
                                    <u>{token.ata.substring(0, 6)}...{token.ata.substring(token.ata.length - 6)}</u>
                                </td>
                                <td
                                    className="cell"
                                >
                                    {token.tokenAmount.uiAmountString}
                                    {
                                        Number(token.tokenAmount.equivalentUSD) > 0 ? ' ($' + token.tokenAmount.equivalentUSD + ')' : ''
                                    }
                                </td>
                                <td
                                    className="cell"
                                >
                                    {token.rent.uiAmountString}
                                    {
                                        Number(token.rent.equivalentUSD) > 0 ? ' ($' + token.rent.equivalentUSD + ')' : ''
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <table style={{
                width: '55%',
                minWidth: '650px',
                borderCollapse: 'collapse',
                tableLayout: 'fixed',
            }}>
                <tfoot>
                    {tokens[standard].length > 0 && (
                        <tr>
                            <td className="cell"  colSpan={3}>
                                <div style={{textAlign: 'center'}}><b>Total ({tokens[standard].length} tokens)</b></div>
                            </td>
                            <td className="cell">
                                {
                                    standard === 'FT' ? <b>${tokens[standard].reduce((acc, token) => acc + Number(token.tokenAmount.equivalentUSD), 0).toFixed(4)}</b> : 'N/A'
                                }
                            </td>
                            <td className="cell">
                                <b>${tokens[standard].reduce((acc, token) => acc + Number(token.rent.equivalentUSD), 0).toFixed(4)}</b>
                            </td>
                        </tr>
                    )}
                    {[...tokens.FT, ...tokens.NFT].filter((token) => selectedTokens.includes(token.ata)).length > 0 && (
                        <tr>
                            <td className="cell"  colSpan={3}>
                                <div style={{textAlign: 'center'}}><b>Selected ({[...tokens.FT, ...tokens.NFT].filter((token) => selectedTokens.includes(token.ata)).length} tokens)</b></div>
                            </td>
                            <td className="cell">
                                <b>${
                                    [...tokens.FT, ...tokens.NFT].filter((token) => selectedTokens.includes(token.ata)).reduce((acc, token) => acc + Number(token.tokenAmount.equivalentUSD), 0).toFixed(4)
                                }</b>
                            </td>
                            <td className="cell">
                                <b>${
                                    [...tokens.FT, ...tokens.NFT].filter((token) => selectedTokens.includes(token.ata)).reduce((acc, token) => acc + Number(token.rent.equivalentUSD), 0).toFixed(4)
                                }</b>
                            </td>
                        </tr>
                    )}
                </tfoot>
            </table>

        </div>
    );
};

export default function TokenList() {
	return (
		<div>
            <_TokenList />
			<br></br>
		</div>
	);
}