import { useEffect, useState } from "react";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

function Modal({ isOpen, onClose, children }: ModalProps) {

    // Add event listener for ESC key
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        window.addEventListener('keydown', handleEsc);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [onClose]);

    if (!isOpen) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000
        }}>
            <div style={{
                backgroundColor: '#F6F5F2',
                padding: '2rem',
                borderRadius: '0',
                maxWidth: '570px',
                maxHeight: '90vh', // Add maximum height
                position: 'relative',
                overflowY: 'auto', // Enable vertical scrolling
                msOverflowStyle: 'none', // Hide scrollbar in IE/Edge
                scrollbarWidth: 'none', // Hide scrollbar in Firefox
            }}>
                <button
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        border: 'none',
                        background: 'none',
                        fontSize: '1.5rem',
                        cursor: 'pointer'
                    }}
                >
                    ×
                </button>
                {children}
            </div>
        </div>
    );
}

const INFO = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <u onClick={() => setIsModalOpen(true)}
               style={{ fontSize: 16, cursor: 'pointer' }}>
                <b>Docs / FAQs</b>
            </u>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <h2 style={{ marginBottom: '25px', textAlign: 'center' }}>Docs / FAQs</h2>
                <ol style={{ paddingLeft: '20px' }}>
                    <li>
                        <b>Select/Unselect Tokens</b>
                        <ul style={{ marginTop: '5px', marginBottom: '20px' }}>
                            <li style={{ marginBottom: '5px' }}>Click <b><u>Symbol</u></b> to select/unselect all tokens listed on the current tap (i.e., <b><u>Fungible Tokens</u></b> or <b><u>Non-Fungible Tokens</u></b>).</li>
                            <li style={{ marginBottom: '5px' }}>Click the specific token symbol to select/unselect a single token.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Convert Selected Tokens to SOL</b> [Free]
                        <ul style={{ marginTop: '5px', marginBottom: '20px' }}>
                            <li style={{ marginBottom: '5px' }}>All the balances of the selected tokens will be swapped to SOL.</li>
                            <li style={{ marginBottom: '5px' }}>Conversion rates are based on current market prices given by <a href="https://jup.ag/" target="_blank" rel="noopener noreferrer">Jupiter</a>.</li>
                            <li style={{ marginBottom: '5px' }}>The maximum number of tokens that can be converted per transaction depends on the routes provided by Jupiter.<sup>†</sup></li>
                        </ul>
                    </li>
                    <li>
                        <b>Burn Selected Tokens</b> [Free]
                        <ul style={{ marginTop: '5px', marginBottom: '20px' }}>
                            <li style={{ marginBottom: '5px' }}>All the balances of the selected tokens will be permanently burned.</li>
                            <li style={{ marginBottom: '5px' }}>The maximum number of tokens that can be burned per transaction is approximately 12.<sup>†</sup></li>
                            <li style={{ marginBottom: '5px' }}><b>PS.</b> This action <b>cannot be undone.</b></li>
                        </ul>
                    </li>
                    <li>
                        <b>Close Selected ATAs</b> [3% Fee]
                        <ul style={{ marginTop: '5px', marginBottom: '20px' }}>
                            <li style={{ marginBottom: '5px' }}>Close the selected ATAs and reclaim the rents reserved in them.<sup>‡</sup></li>
                            <li style={{ marginBottom: '5px' }}>An ATA <b>can only be closed if the corresponding token balance is <span style={{ fontSize: '1.2em' }}>ZERO</span></b> (except for the one of <u>Wrapped SOL</u> token). If the balance is not zero, convert the token to SOL or burn it beforehand to close the ATA.</li>
                            <li style={{ marginBottom: '5px' }}>The rent (SOL) deposited in the ATAs will be refunded to your wallet after they are closed.</li>
                            <li style={{ marginBottom: '5px' }}>The maximum number of ATAs that can be closed per transaction is approximately 20.<sup>†</sup></li>
                            <li style={{ marginBottom: '5px' }}>This platform takes 3% of the refunded rent as a fee to cover the cost of running the service.</li>
                            <li style={{ marginBottom: '5px' }}><b>PS.</b> The ATAs <b>can be re-opened anytime</b>.</li>
                        </ul>
                    </li>
                </ol>
                <p style={{ marginTop: '25px' }}><sup>†</sup>Solana restricts <a href="https://solana.com/docs/core/transactions#:~:text=The%20maximum%20size%20of%20a%20transaction%20is%201232%20bytes." target="_blank" rel="noopener noreferrer">the maximum size of a transaction</a>.</p>
                <p style={{ marginTop: '-13px' }}><sup>‡</sup>Please refer to <a href="https://www.alchemy.com/overviews/associated-token-account" target="_blank" rel="noopener noreferrer">this</a> page for more information about ATAs.</p>
                <p style={{ marginTop: '-13px' }}><sup>*</sup>If you encounter any issues, please DM us via <a href="https://x.com/" target="_blank" rel="noopener noreferrer">Twitter</a>.</p>
            </Modal>
        </>
    );
    // return (
    //     <u onClick={() => window.open('https://www.google.com')}
    //         style={ {display: 'inline-flex', flexDirection: 'row', fontSize: 16, cursor: 'pointer'} }>
    //         Docs
    //     </u>
    // );
};

const Twitter = () => {
    return (
        <u onClick={() => window.open('https://x.com/')}
            style={ {fontSize: 16, cursor: 'pointer'} }>
            <b>Twitter</b>
        </u>
    );
};

// const Telegram = () => {
//     return (
//         <u onClick={() => window.open('https://www.google.com')}
//             style={ {fontSize: 16, cursor: 'pointer'} }>
//             Telegram
//         </u>
//     );
// };


export default function Footer() {
    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '2rem'
            }}>
                <INFO />
                <Twitter />
                {/* <Telegram /> */}
            </div>
            <footer>
                <p>&copy; 2024 Solana Rent Refund Platform</p>
            </footer>
        </div>
    );
}