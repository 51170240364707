import { Adapter, BaseSignerWalletAdapter, UnifiedWalletProvider, WalletAdapterNetwork } from "@jup-ag/wallet-adapter";
import { ReactNode, useMemo } from "react";
import { FC } from "react";
import * as AllWalletAdapters from '@solana/wallet-adapter-wallets';
import { TransactionVersion } from '@solana/web3.js';

type WalletAdapterWithMutableSupportedTransactionVersions<T> = Omit<T, 'supportedTransactionVersions'> & {
    supportedTransactionVersions: BaseSignerWalletAdapter['supportedTransactionVersions'];
};

const metadata = {
    name: 'UnifiedWallet',
    description: 'UnifiedWallet',
    url: 'https://jup.ag',
    iconUrls: ['https://jup.ag/favicon.ico'],
    additionalInfo: '',
    walletConnectProjectId: '4a4e231c4004ef7b77076a87094fba61',
};

/**
 * WalletProvider is a component that provides a unified wallet provider for the app.
 * It is used to connect to the wallet and provide the necessary context for the app to use the wallet.
 */
const WalletProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const wallets = useMemo(() => {
        if (typeof window === "undefined") {
          return [];
        }

        const {
            UnsafeBurnerWalletAdapter: _,
            WalletConnectWalletAdapter,
            ...allwalletAdapters
        } = AllWalletAdapters;

        const walletAdapters = Object.keys(allwalletAdapters)
            .filter((key) => key.includes("Adapter"))
            .map((key) => (allwalletAdapters as any)[key])
            .map((WalletAdapter: any) => new WalletAdapter()); // Intentional any, TS were being annoying

        const walletConnectWalletAdapter: WalletAdapterWithMutableSupportedTransactionVersions<BaseSignerWalletAdapter> | null =
            (() => {
                    const adapter: WalletAdapterWithMutableSupportedTransactionVersions<BaseSignerWalletAdapter> =
                        new WalletConnectWalletAdapter({
                            network: WalletAdapterNetwork.Mainnet,
                            options: {
                            relayUrl: "wss://relay.walletconnect.com",
                            projectId: metadata.walletConnectProjectId,
                            metadata: {
                                name: metadata.name,
                                description: metadata.description,
                                url: metadata.url,
                                icons: metadata.iconUrls,
                            },
                            },
                        });

                    // While sometimes supported, it mostly isn't. Should this be dynamic in the wallet-adapter instead?
                    adapter.supportedTransactionVersions = new Set(['legacy' as TransactionVersion]);
                    return adapter;
            })();

        return [...walletAdapters, walletConnectWalletAdapter].filter(
          (item) => item && item.name && item.icon,
        );
    }, [metadata]);

    return (
        <UnifiedWalletProvider
            wallets={wallets}
            config={{
                autoConnect: true,
                env: "mainnet-beta",
                metadata: {
                    name: "UnifiedWallet",
                    description: "UnifiedWallet",
                    url: "https://jup.ag",
                    iconUrls: ["https://jup.ag/favicon.ico"],
                },
                notificationCallback: {
                    onConnect: () => {},
                    onConnecting: () => {},
                    onDisconnect: () => {},
                    onNotInstalled: () => {},
                },
                walletlistExplanation: {
                    href: "https://station.jup.ag/docs/additional-topics/wallet-list",
                },
                theme: "light",
                lang: "en",
            }}
        >
            { children }
        </UnifiedWalletProvider>
    );
};

export default WalletProvider;