import { useEffect, useState } from "react";
import { useWallet } from "@jup-ag/wallet-adapter";
import useTokenSort from "./sort";

enum TokenStandard {
    FT = 1,
    NFT = 2,
}

export type InitializedAccount = {
    symbol: string,
    mint: string,
    ata: string,
    tokenAmount: {
        uiAmountString: string,
        equivalentUSD: string
    },
    rent: {
        uiAmountString: string,
        equivalentUSD: string
    }
};

export default function useTokens() {
    const [tokens, setTokens] = useState<{ 'FT': InitializedAccount[], 'NFT': InitializedAccount[] }>({ 'FT': [], 'NFT': [] });
    const { sortTokens, sortDirection } = useTokenSort();
    const { publicKey } = useWallet();

    async function fetchTokens() {
        if (!publicKey) return;
        try {
            // console.log(`fetchTokens`, Math.floor(Date.now() / 1000));
            const response = await fetch(`http://localhost:3001/api/atas?userAddress=${publicKey.toString()}`);
            const tokens = await response.json();
            if (tokens.error) {
                throw new Error(tokens.error);
            }
            const result: { 'FT': InitializedAccount[], 'NFT': InitializedAccount[] } = { 'FT': [], 'NFT': [] };
            for (const token of tokens) {
                if (token.tokenStandard === TokenStandard.FT) {
                    result['FT'].push(token);
                } else {
                    result['NFT'].push(token);
                }
            }
            const sortedFT = sortTokens(result['FT'], sortDirection);
            const sortedNFT = sortTokens(result['NFT'], sortDirection);
            setTokens({ 'FT': sortedFT, 'NFT': sortedNFT });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchTokens();
        const interval = setInterval(() => {
			fetchTokens();
		}, 90000);
		return () => clearInterval(interval);
    }, [publicKey]);

    return { tokens, fetchTokens, setTokens };
}