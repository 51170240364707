import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

type MessagesContextType = {
    messages: { id: string; content: string }[];
    setMessages: (messages: { id: string; content: string }[]) => void;
};

export const formatMessage = (message: string) => {
    return `[${new Date().toLocaleTimeString().replace(' ', '')}] ${message}`;
};

const MessagesContext = createContext<MessagesContextType | undefined>(undefined);

export const MessagesProvider = ({ children }: { children: ReactNode }) => {
    const [messages, setMessages] = useState<{ id: string; content: string }[]>([]);

    useEffect(() => {
        // shift messages if there are more than 8
        if (messages.length > 8) {
            setMessages(messages.slice(1));
        }
        messages.forEach(({ id }) => {
            setTimeout(() => {
                setMessages((prev) =>
                    prev.filter((msg) => msg.id !== id)
                );
            }, 20000); // 20 seconds
        });
    }, [messages]);

    return (
        <MessagesContext.Provider value={{ messages, setMessages }}>
            {children}
        </MessagesContext.Provider>
    );
};

export default function useMessages() {
    const context = useContext(MessagesContext);
    if (!context) {
        throw new Error('useMessages must be used within a MessagesProvider');
    }
    return context;
};