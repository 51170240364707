import React, { createContext, useContext, useState, ReactNode } from 'react';

type SelectedTokensContextType = {
    selectedTokens: string[];
    setSelectedTokens: (tokens: string[]) => void;
};

const SelectedTokensContext = createContext<SelectedTokensContextType | undefined>(undefined);

export const SelectedTokensProvider = ({ children }: { children: ReactNode }) => {
    const [selectedTokens, setSelectedTokens] = useState<string[]>([]);

    return (
        <SelectedTokensContext.Provider value={{ selectedTokens, setSelectedTokens }}>
            {children}
        </SelectedTokensContext.Provider>
    );
};

export default function useSelectedTokens() {
    const context = useContext(SelectedTokensContext);
    if (!context) {
        throw new Error('useSelectedTokens must be used within a SelectedTokensProvider');
    }
    return context;
};