import { UnifiedWalletButton } from "@jup-ag/wallet-adapter";
import useMessages from "../contexts/MessagesContext";

export default function Sidebar() {
    const { messages } = useMessages();

    return (
        <div style={{width: '100%'}}>
            <div
                className="wallet-button-container"
                style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    marginBottom: 20,
                    width: '100%'
                }}
            >
                <UnifiedWalletButton overrideContent={<div><u style={{backgroundColor: '#F6F5F2'}}>Connect Wallet</u></div>}/>
            </div>
            {messages.length > 0 && (
                <div
                    style={{
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    <div style={{ textAlign: 'justify' }}>
                        {messages.map(messageObj => {
                            const message = messageObj.content;
                            return message.includes('Done:') ? (
                                <div key={messageObj.id} style={{ marginBottom: 5 }}>
                                    {message.split('Done:')[0]} Done:&nbsp;
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`https://solscan.io/tx/${message
                                            .split('Done:')[1]
                                            .substring(1)}`}
                                    >
                                        {message
                                            .split('Done:')[1]
                                            .slice(1, 9)}
                                        ...
                                        {message
                                            .split('Done:')[1]
                                            .slice(-8)}
                                    </a>
                                </div>
                            ) : (
                                <div key={messageObj.id} style={{ marginBottom: 5 }}>
                                    {message}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>

    );
}