import { FC } from "react";
import WalletProvider from "./contexts/WalletContext";
import { SelectedTokensProvider } from "./contexts/SelectedTokensContext";
import { MessagesProvider } from "./contexts/MessagesContext";
import Header from "./components/Header";
import TokenList from "./components/TokenList";
import TxSender from "./components/TxSender";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";

const App: FC = () => {
    return (
        <div style={{
            height: '100vh',  // Make container at least full viewport height
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between' // Pushes footer to bottom
        }}>
            <WalletProvider>
                <SelectedTokensProvider>
                    <MessagesProvider>
                        <div className="App" style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <Header />
                                <TxSender />
                                <TokenList />
                            </div>
                            <div style={{
                                position: 'absolute',
                                marginTop: 12,
                                right: '20px', // Add some padding from the right
                                zIndex: 1000,   // Ensure it stays on top of other elements
                                width: '13%',
                                minWidth: '100px',
                                marginLeft: '20px',
                                height: 'fit-content'
                            }}>
                                <Sidebar />
                            </div>
                        </div>
                    </MessagesProvider>
                </SelectedTokensProvider>
            </WalletProvider>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: 12}}>
                <Footer />
            </div>
        </div>

    );
};

export default App;
