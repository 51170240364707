import { useWallet } from "@jup-ag/wallet-adapter";
import useSelectedTokens from "../contexts/SelectedTokensContext";
import useTokens from "../hooks/tokens";
import { Connection, VersionedTransaction } from "@solana/web3.js";
import useMessages, { formatMessage } from "../contexts/MessagesContext";
import { v4 as uuidv4 } from 'uuid';

const connection = new Connection("https://solana-mainnet.g.alchemy.com/v2/rxk5fVfLD73hBWs48_l-zSHHwcFsGpIi");
const baseUrl = "http://localhost:3001";

export default function TxSender() {
    const { publicKey, sendTransaction } = useWallet();
    const { selectedTokens } = useSelectedTokens();
    const { tokens } = useTokens();
    const { messages, setMessages } = useMessages();

    const _sendTransaction = (tx: VersionedTransaction, message: string) => {
        try {
            const formattedMessage = formatMessage(`Sending Tx to ${message} ...`);
            const newMessages = [...messages, { id: uuidv4(), content: formattedMessage }];
            setMessages(newMessages);
            sendTransaction(tx, connection).then((res) => {
                const formattedMessage = formatMessage(`Tx Done: ${res}`);
                setMessages([...newMessages, { id: uuidv4(), content: formattedMessage }]);
            }).catch((error) => {
                const formattedMessage = formatMessage(`Error: ${error.message}`);
                setMessages([...newMessages, { id: uuidv4(), content: formattedMessage }]);
            });
        } catch (error) {
            // should not happen
            console.error(error);
        }
    }

    const handleConvertSelectedTokensToSOL = async () => {
        if (!publicKey) return;
        const validSelectedTokens = new Set<string>();
        tokens['FT'].map((token) => {
            if (selectedTokens.includes(token.ata)) {
                if (Number(token.tokenAmount.uiAmountString) > 0) {
                    validSelectedTokens.add(token.ata);
                }
            }
        });
        if (validSelectedTokens.size === 0) return;
        try {
            // console.log(`convert tokens`, Math.floor(Date.now() / 1000));
            const response = await fetch(baseUrl + `/api/convert-tokens?userAddress=${publicKey.toString()}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Array.from(validSelectedTokens))
            });
            if (response.status > 200) {
                const originalErrMsg = (await response.json()).error;
                const errMsg = originalErrMsg.length > 0 ? originalErrMsg : 'Unknown error';
                throw new Error(errMsg);
            }
            // get serialized tx
            const rawTx = await response.json();
            const txBytes = new Uint8Array(Object.values(rawTx));
            const tx = VersionedTransaction.deserialize(txBytes);
            _sendTransaction(tx, 'Convert Selected Tokens to SOL');
        } catch (error: any) {
            let formattedMessage;
            if (error.message.includes('encoding overruns Uint8Array')) {
                formattedMessage = formatMessage(`Error: Tx is too large; Try to select fewer tokens for conversion.`);
            } else {
                formattedMessage = formatMessage(`Error: ${error.message}`);
            }
            setMessages([...messages, { id: uuidv4(), content: formattedMessage }]);
        }
    }

    const handleBurnSelectedTokens = async () => {
        if (!publicKey) return;
        const validSelectedTokens = new Set<string>();
        [...tokens['FT'], ...tokens['NFT']].map((token) => {
            if (selectedTokens.includes(token.ata)) {
                if (Number(token.tokenAmount.uiAmountString) > 0) {
                    validSelectedTokens.add(token.ata);
                }
            }
        });
        if (validSelectedTokens.size === 0) return;
        try {
            // console.log(`burn tokens`, Math.floor(Date.now() / 1000));
            const response = await fetch(baseUrl + `/api/burn-tokens?userAddress=${publicKey.toString()}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Array.from(validSelectedTokens))
            });
            if (response.status > 200) {
                const originalErrMsg = (await response.json()).error;
                const errMsg = originalErrMsg.length > 0 ? originalErrMsg : 'Unknown error';
                throw new Error(errMsg);
            }
            // get serialized tx
            const rawTx = await response.json();
            const txBytes = new Uint8Array(Object.values(rawTx));
            const tx = VersionedTransaction.deserialize(txBytes);
            _sendTransaction(tx, 'Burn Selected Tokens');
        } catch (error: any) {
            let formattedMessage;
            if (error.message.includes('encoding overruns Uint8Array')) {
                formattedMessage = formatMessage(`Error: Tx is too large; Burn at most ~12 tokens at once.`);
            } else {
                formattedMessage = formatMessage(`Error: ${error.message}`);
            }
            setMessages([...messages, { id: uuidv4(), content: formattedMessage }]);
        }
    }

    const handleCloseSelectedATAs = async () => {
        if (!publicKey) return;
        const validSelectedTokens = new Set<string>();
        [...tokens['FT'], ...tokens['NFT']].map((token) => {
            if (selectedTokens.includes(token.ata)) {
                if (Number(token.tokenAmount.uiAmountString) === 0 || token.mint === 'So11111111111111111111111111111111111111112') {
                    validSelectedTokens.add(token.ata);
                }
            }
        });
        if (validSelectedTokens.size === 0) return;
        try {
            // console.log(`close atas`, Math.floor(Date.now() / 1000));
            const response = await fetch(baseUrl + `/api/close-atas?userAddress=${publicKey.toString()}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Array.from(selectedTokens))
            });
            if (response.status > 200) {
                const originalErrMsg = (await response.json()).error;
                const errMsg = originalErrMsg.length > 0 ? originalErrMsg : 'Unknown error';
                throw new Error(errMsg);
            }
            // get serialized tx
            const rawTx = await response.json();
            const txBytes = new Uint8Array(Object.values(rawTx));
            const tx = VersionedTransaction.deserialize(txBytes);
            _sendTransaction(tx, 'Close Selected ATAs & Reclaim Rents');
        } catch (error: any) {
            let formattedMessage;
            if (error.message.includes('encoding overruns Uint8Array')) {
                formattedMessage = formatMessage(`Error: Tx is too large; Close at most ~20 ATAs at once.`);
            } else {
                formattedMessage = formatMessage(`Error: ${error.message}`);
            }
            setMessages([...messages, { id: uuidv4(), content: formattedMessage }]);
        }
    }

    return (
        <div style={{marginBottom: 30}}>
            <u onClick={async () => await handleConvertSelectedTokensToSOL()}
                style={ {fontSize: 16, cursor: 'pointer'} }>
                Convert Selected Tokens to SOL
            </u>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <u onClick={async () => await handleBurnSelectedTokens()}
                style={ {fontSize: 16, cursor: 'pointer'} }>
                Burn Selected Tokens
            </u>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <u onClick={async () => await handleCloseSelectedATAs()}
                style={ {fontSize: 16, cursor: 'pointer'} }>
                Close Selected ATAs & Reclaim Rents
            </u>
        </div>
    );
}