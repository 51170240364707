import { useState } from 'react';
import { InitializedAccount } from './tokens';

export default function useTokenSort() {
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

    const sortTokens = (tokens: InitializedAccount[], newDirection: 'asc' | 'desc') => {
        if (tokens.length <= 1) return tokens;

        tokens.sort((a, b) => {
            return a.mint.localeCompare(b.mint);
        });
        tokens.sort((a, b) => {
            const aSymbol = a.symbol.length > 0 ? a.symbol : 'zzzzzzzzzzz';
            const bSymbol = b.symbol.length > 0 ? b.symbol : 'zzzzzzzzzzz';
            return aSymbol.localeCompare(bSymbol);
        });
        tokens.sort((a, b) => {
            const tokenABalance = Number(a.tokenAmount.uiAmountString);
            const tokenBBalance = Number(b.tokenAmount.uiAmountString);
            return newDirection === 'asc' ? tokenABalance - tokenBBalance : tokenBBalance - tokenABalance;
        });
        return [...tokens].sort((a, b) => {
            const tokenAValue = Number(a.tokenAmount.equivalentUSD);
            const tokenBValue = Number(b.tokenAmount.equivalentUSD);
            return newDirection === 'asc' ? tokenAValue - tokenBValue : tokenBValue - tokenAValue;
        });
    };

    return { sortTokens, sortDirection, setSortDirection };
};